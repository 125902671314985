var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3",attrs:{"flat":""}},[_c('p',{staticClass:"clav-content-title-1"},[_vm._v("Legislação")]),_c('p',{staticClass:"clav-content-text px-8 py-2"},[_vm._v(" Catálogo de legislação que regula os processos de negócio (classes de 3º nível da Lista Consolidada) e enquadra os respetivos prazos de conservação administrativa (PCA) e destino final (DF). Do catálogo constam ainda as fontes de legitimação, isto é, a portaria ou despacho que integra as tabelas de seleção, onde estão estabelecidas as decisões de avaliação que fundamentam a ação de eliminação. ")]),_c('p',{staticClass:"clav-content-text px-8 py-2"},[_vm._v(" Para aceder à legislação, selecione a opção "),_c('b',[_vm._v("CONSULTAR")]),_vm._v(". ")]),_c('p',{staticClass:"clav-content-text px-8 py-2"},[_vm._v(" Para adicionar legislação, selecione a opção "),_c('b',[_vm._v("ADICIONAR. Deve estar registado na CLAV para aceder a esta funcionalidade.")])]),_c('p',{staticClass:"clav-content-text px-8 py-2"},[_vm._v(" Para editar legislação, selecione a opção "),_c('b',[_vm._v("EDITAR. Deve estar registado na CLAV para aceder a esta funcionalidade.")])]),_c('v-container',{staticStyle:{"text-align":"center"},attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"white--text clav-linear-background",class:{
            'px-8': _vm.$vuetify.breakpoint.lgAndUp,
            'px-2': _vm.$vuetify.breakpoint.mdAndDown,
          },attrs:{"rounded":""},on:{"click":function($event){return _vm.$router.push('/legislacao/consultar')}}},[_c('unicon',{attrs:{"name":"consultar-icon","width":"20","height":"20","viewBox":"0 0 20.71 20.697","fill":"#ffffff"}}),_c('p',{staticClass:"ml-2"},[_vm._v("Consultar")])],1)],1),(
          [1, 3, 3.5, 4, 5, 6, 7].includes(this.$userLevel()) &&
          this.$store.state.token != '' &&
          this.$store.state.name != ''
        )?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"white--text clav-linear-background",class:{
            'px-8': _vm.$vuetify.breakpoint.lgAndUp,
            'px-2': _vm.$vuetify.breakpoint.mdAndDown,
          },attrs:{"rounded":""},on:{"click":function($event){return _vm.$router.push('/legislacao/criar')}}},[_c('unicon',{attrs:{"name":"criar-icon","width":"20","height":"20","viewBox":"0 0 20.71 20.721","fill":"#ffffff"}}),_c('p',{staticClass:"ml-2"},[_vm._v("Criar")])],1)],1):_vm._e(),(
          [4, 5, 6, 7].includes(this.$userLevel()) &&
          this.$store.state.token != '' &&
          this.$store.state.name != ''
        )?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"white--text clav-linear-background",class:{
            'px-8': _vm.$vuetify.breakpoint.lgAndUp,
            'px-2': _vm.$vuetify.breakpoint.mdAndDown,
          },attrs:{"rounded":""},on:{"click":function($event){_vm.legislacaoDialog = true}}},[_c('unicon',{attrs:{"name":"alterar-icon","width":"20","height":"20","viewBox":"0 0 20.71 20.727","fill":"#ffffff"}}),_c('p',{staticClass:"ml-2"},[_vm._v("Alterar")])],1)],1):_vm._e()],1)],1),_c('CaixaDeDialogo',{attrs:{"ativo":_vm.legislacaoDialog,"items":_vm.legislacao.legislacaoItems,"tipo":"Legislação"},on:{"fechar":function($event){_vm.legislacaoDialog = !_vm.legislacaoDialog},"editar":function($event){return _vm.editar($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }